

.App {
  text-align: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

/* Navigation styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar h1 {
  margin: 0;
  color: #333;
  font-size: 1.8rem;
}

.login-btn {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.login-btn:hover {
  background-color: #0056b3;
}

/* Upload section styles */
.upload-section {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.upload-section h2 {
  color: #333;
  margin-bottom: 2rem;
}

.upload-container {
  padding: 2rem;
}

.drag-drop-area {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 3rem 2rem;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.2s, background-color 0.2s;
}

.drag-drop-area:hover,
.drag-drop-area.drag-over {
  border-color: #007bff;
  background-color: #f8f9fa;
}

.drag-drop-area p {
  margin: 0 0 1rem 0;
  color: #666;
  font-size: 1.1rem;
}

.drag-drop-area span {
  display: block;
  margin: 0.5rem 0;
  color: #999;
}

.upload-btn {
  margin-top: 1rem;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upload-btn:hover {
  background-color: #218838;
}

/* Add this class to drag-drop-area when file is being dragged over */
.drag-over {
  border-color: #28a745;
  background-color: rgba(40, 167, 69, 0.1);
}
